<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-md" v-if="data">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{data.category.name}}</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <svg aria-hidden="true" style="width: 15px;" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-xmark fa-xl"><path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" class=""></path></svg>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <div class="row">
                <div class="col-md-6">
                  <h1 data-v-fae5bece="" class="text-left font-w-100" :style="`color: ${data.color}`"> {{data.spent | currency }} </h1>
                  <h4 data-v-fae5bece="" class="text-left block" style="color: rgb(166, 166, 166); font-weight: 500; font-size: 20px; margin-top: 18px;">Meta</h4>
                  <h3 data-v-fae5bece="" class="text-left font-w-100"> {{data.value | currency }} </h3>
                  <h4 data-v-fae5bece="" class="text-left block" style="color: rgb(166, 166, 166); font-weight: 500; font-size: 20px; margin-top: 18px;">Data</h4>
                  <h3 data-v-fae5bece="" class="text-left font-w-100"> {{ data.dead_line | moment("DD/MM/YYYY") }} </h3>
                </div>
                <div class="col-md-6">
                  <div class="card-body no-padding relative">
                    <section class="text-center">
                      <svg
                        data-v-31a768e4=""
                        width="220"
                        height="220"
                        xmlns="http://www.w3.org/2000/svg"
                        class="circle-chart"
                        viewBox="-3 0 40 30"
                      >
                        <circle
                          class="circle-chart__background"
                          stroke="#efefef"
                          stroke-width="2"
                          fill="none"
                          cx="16.91549431"
                          cy="16.91549431"
                          r="15.91549431"
                        />
                        <circle
                          class="circle-chart__circle"
                          :stroke="`${data.color}`"
                          stroke-width="2"
                          :stroke-dasharray="`${data.progress},100`"
                          stroke-linecap="round"
                          fill="none"
                          cx="16.91549431"
                          cy="16.91549431"
                          r="15.91549431"
                        />
                        <g class="circle-chart__info">
                          <text
                            class="circle-chart__percent font-w-100"
                            x="16.91549431"
                            y="20"
                            alignment-baseline="central"
                            text-anchor="middle"
                            font-size="8"
                          >
                            {{data.progress}}%
                          </text>
                        </g>
                      </svg>
                    </section>
                    <div></div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <hr/>
                  <p style="color: #9d9d9d">
                    {{ data.description }}
                  </p>
                  Você pode salvar {{data.installment_value | currency }} {{type}}
                  Parcelas: {{data.transactions.length}} / {{data.installments }}
                </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <h3 class="display-4">Transações</h3>
                <button type="button" v-tooltip.top="'Nova transação'" @click="showAdd = true, new_transaction = {}" class="btn btn-green color-white btn-green-shadow btn-add-transaction">
                  <i class="fa fa-plus"></i>
                </button>
                <button @click="removeGoal" type="button" v-tooltip.top="'Remover objetivo'" class="btn btn-red color-white btn-red-shadow btn-remove-transaction">
                  <i class="fa fa-trash"></i>
                </button>
                <button @click="editGoal" type="button" v-tooltip.top="'Editar objetivo'" class="btn btn-info-trans color-white btn-info-shadow btn-info-transaction">
                  <i class="fa fa-cog"></i>
                </button>
                <hr>
                <div class="form-group" v-if="showAdd">
                  <div class="row">
                      <div class="col-md-12">
                        <div class="input-group">
                          <money v-model="new_transaction.value" v-bind="money" class="form-control"></money> 
                          <div class="input-group-append">
                            <button class="btn btn-success btn-sm btn-save-transaction" v-tooltip.top="'Salvar'" @click="addTransaction">Salvar</button>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <ul class="list-group" v-if="data.transactions">
                  <li class="list-group-item ng-scope" v-for="(item, i) in data.transactions" :key="i">
                    <div class="row">
                      <div class="col-sm-6 col-sm-6 col-lg-6 col-xs-6">
                        <span class="text-success" ><span class="ng-binding">+ {{item.amount | currency }}</span></span>
                      </div>
                      <div class="col-sm-6 col-sm-6 col-lg-6 col-xs-6 text-right">
                        <span class="ng-binding">{{ item.transaction_date | moment("DD/MM/YYYY") }}&nbsp;&nbsp;</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <button class="btn btn-info pull-right action-add w-100" type="button" v-if="data.status == 'open'" @click="updateGoal('closed')"><span><i class="mdi mdi-plus-circle-outline"></i></span>  Concluir objetivo</button>
                <button class="btn btn-info pull-right action-add w-100" type="button" v-if="data.status == 'closed'" @click="updateGoal('open')"><span><i class="mdi mdi-plus-circle-outline"></i></span>  Abrir objetivo</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/mixins/Modal';
import { Money } from 'v-money';
import moment from 'moment-timezone';
import AppService from '@/services/app.service';
import Swal from 'sweetalert2';

export default {
  mixins: [Modal],
  props: ['id'],
  components: {
    Money,
  },
  data() {
    return {
      showAdd: false,
      new_transaction: {},
      type: ' por mês',
      report: {},
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      tabIncome: 'expense',
      tabColor: '#ff1a1a',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
    };
  },
  mounted() {
    this.$root.$on('show.modal', this.checkGoal);
  },
  provide: {
  },
  created() {
  },
  methods: {
    removeGoal() {
      this.$root.$emit('call_remove_modal', this.data.id);
      this.hide();
    },
    editGoal() {
      this.$root.$emit('call_edit_modal', this.data);
      this.hide();
    },
    changeTab(tab) {
      this.miniTab.tab = tab;
      switch (tab) {
        case 0:
          this.tabIncome = 'expense';
          break;
        case 1:
          this.tabIncome = 'income';
          break;
        default:
          break;
      }
    },
    addTransaction() {
      this.data.transactions.push({
        amount: this.new_transaction.value,
        transaction_date: moment().format("YYYY/MM/DD"),
      });
      this.$toast.show({
        title: 'Sucesso',
        content: 'Transação adicionada!',
        type: 'success',
      });
      AppService.addGoalTransaction({
        goal_id: this.data.id,
        amount: this.new_transaction.value,
        transaction_date: moment().format('YYYY-MM-DD'),
      }).then(
        (response) => {
          this.data = {
            id: response.goal.id,
            category: this.data.category,
            transactions: response.goal.transactions,
            spent: response.goal.spent,
            progress: response.goal.progress,
            color: response.goal.color,
            value: response.goal.value,
            status: response.goal.status,
            dead_line: response.goal.dead_line,
            installment_value: response.goal.installment_value,
          };
          this.$emit('updateGoal');
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
      this.new_transaction = {};
    },
    finishGaol(status) {
      this.$toast.show({
        title: 'Sucesso',
        content: 'Objetivo atualizado',
        type: 'success',
      });
      AppService.openFinish({
        id: this.data.id,
        status: status,
      }).then(
        (response) => {
          console.log(response);
          this.data.status = response.goal.status;
          this.$emit('updateGoal');
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    checkGoal() {
      this.$forceUpdate();
      if(this.data) {
        switch (this.data.type) {
          case 'week':
            this.type = 'por semana';
            break;
          case 'month':
            this.type = 'por mês';
            break;
        }
      }
    },
    updateGoal(status) {
      Swal.fire({
        title: `Atualizar objetivo?`,
        text: 'Tem certeza que deseja atualizar o objetivo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.finishGaol(status);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-add-transaction {
  height: 45px;
  position: absolute;
  right: 15px;
  z-index: 1;
  top: 10px;
  text-align: center;
}

.btn-remove-transaction {
  height: 45px;
  position: absolute;
  right: 105px;
  z-index: 1;
  top: 10px;
}
.btn-info-transaction {
  height: 45px;
  position: absolute;
  right: 195px;
  z-index: 1;
  top: 10px;
}

textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
}

.actions-var {
  position: relative !important;
}
.emoji-color {
  color: #ccd2dc !important;
}
.actions-var-cancel {
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding-top: 5px;
  padding-bottom: 0px;
}
.actions-icon {
  font-size: 14px !important;
}
.actions-var a {
  cursor: pointer;
}
.fr-wrapper > *:first-child {
  display: none;
}
.fr-wrapper .fr-element {
  display: block;
}
.second-toolbar a {
  visibility: hidden;
}
#cke_673 {
  display: none !important;
}
.cke_toolbar_last {
  display: none;
}
.modal-body {
  padding: 25px;
}
.popup-icons {
  height: 50px;
}
.popup-icons .stpe-icons-center {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 64px;
  margin: auto;
}
.popup-icons .stpe-icons-center a.stpe-logo-banks {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  background: #fff;
  overflow: hidden;
  cursor: default;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.popup-icons .stpe-icons-center a.stpe-logo-banks img {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 60px;
  margin: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.stpe-component_dropdown .stpe-dropdown-area {
  display: none;
  position: absolute;
  top: 15px;
  left: 120px;
  width: 190px;
  margin-top: 4px;
  margin-left: -95px;
  padding-top: 12px;
  z-index: 2;
}
.drop-down-select-month:hover .stpe-dropdown-area{
  display: block;
}
.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box {
  background: #fff;
  -webkit-box-shadow: 0 2px 20px rgba(0,0,0,.15);
  -moz-box-shadow: 0 2px 20px rgba(0,0,0,.15);
  box-shadow: 0 2px 20px rgba(0,0,0,.15);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box .icon-caret-up {
  position: absolute;
  top: -5px;
  left: 120px;
  margin-left: -10px;
  color: #fff;
  font-size: 21px;
}
.stpe-dropdown-box ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box ul li {
  float: none;
}
.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box.stpe-dropdown-text-center a {
  display: block;
  text-align: left;
}
.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box ul li a {
  display: block;
  padding: 7px 18px;
  text-align: left;
  text-transform: none;
  color: #777;
  font-size: 14px;
  letter-spacing: 0;
  -webkit-transition: all .15s ease-out 0s;
  -moz-transition: all .15s ease-out 0s;
  -o-transition: all .15s ease-out 0s;
  transition: all .15s ease-out 0s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 99.9%;
  white-space: nowrap;
}
.stpe-dropdown-li {
  cursor: pointer;
}
.stpe-context_transactions-dialog form .input-row .drop-down-select-month a {
  font-size: 12px;
  line-height: 12px;
}
.stpe-dropdown-action {
  color: #18b272;
}
.stpe-invoice-active {
  background: #44e8a4;
}
.stpe-invoice-active a{
  color: #fff !important;
}
.mx-datepicker {
  width: 100% !important;
}
.alert-error {
  list-style: none;
  margin: 0px;
  padding: 15px;
}
.alert-error .alert {
  padding: 0;
  padding-left: 0px;
  padding-left: 8px;
  font-size: 13px;
  margin-bottom: 10px;
}
.chart-line {
  height: 350px;
  margin-top: 30px;
}
.btn-save-transaction {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border: none !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
</style>
